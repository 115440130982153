import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <center>{`
	`}<img parentName="center" {...{
        "width": "300rem",
        "src": "https://user-images.githubusercontent.com/51112542/109654721-df3bf180-7ba5-11eb-9b81-ef62f3d0ee61.png"
      }}></img>
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      